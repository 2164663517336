import { Route, Routes } from "react-router-dom";

import Rsvp from "./modules/rsvp/Rsvp";
import Attendance from "./modules/attendance/Attendance";

function AppRouter() {
  return (
    <Routes>
      <Route exact path="/" element={<Rsvp />} />
      <Route exact path="/replies" element={<Attendance />} />
    </Routes>
  );
}

export default AppRouter;
