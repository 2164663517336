import { useState } from "react";

import { Box, Container, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import "./Topbar.scss";

export default function Topbar() {
  const [active, setActive] = useState(false);

  return (
    <Box component={"section"} id="topbar">
      <Container>
        <Box component={"nav"}>
          <Typography className="text-logo">Kim and Neil</Typography>
          <IconButton
            color="white"
            onClick={() => setActive(!active)}
            className="menu-button"
          >
            {active ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
          <Box
            component={"ul"}
            className={`main-nav ${active ? "active" : ""}`}
          >
            <Box component={"li"}>
              <a href="#home-banner" onClick={() => setActive(false)}>
                Home
              </a>
            </Box>
            <Box component={"li"}>
              <a href="#we-celebrate" onClick={() => setActive(false)}>
                Celebration
              </a>
            </Box>
            <Box component={"li"}>
              <a href="#details" onClick={() => setActive(false)}>
                Details
              </a>
            </Box>
            <Box component={"li"} onClick={() => setActive(false)}>
              <a href="#rsvp">RSVP</a>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
