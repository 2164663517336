import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAyQIChNXIr_Wi0nNgbI8a-XO09BQ0W090",
  authDomain: "kimandpat-1712500718290.firebaseapp.com",
  projectId: "kimandpat-1712500718290",
  storageBucket: "kimandpat-1712500718290.appspot.com",
  messagingSenderId: "630179123515",
  appId: "1:630179123515:web:3e9a05fe60d7a7779b671e",
  measurementId: "G-N8ZTRSC3KS",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
