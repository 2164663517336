import React, { useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase";
import moment from "moment";

import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import "./Rsvp.scss";

import FirebaseService from "../../services/FirebaseService";

import HomeBannerBg from "../../assets/home-banner-bg.png";
import WeCelebrate from "../../assets/we-celebrate-bg.png";
import When from "../../assets/when.PNG";
import Where from "../../assets/where.PNG";
import What from "../../assets/what.jpg";
import PresenceImage from "../../assets/presence-image.png";
import Heart from "../../assets/heart.png";

export default function Rsvp() {
  const [snackbar, setSnackbar] = useState({
    x: "right",
    y: "top",
    open: false,
    duration: 4000,
    text: "",
    severity: "succes",
  });

  const [user, setUser] = useState({
    fullName: "",
    email: "",
    password: "Kim&Pat2024",
  });

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setUser((user) => ({ ...user, [name]: value }));
  };

  const handleReservation = () => {
    if (user.fullName === "") {
      setSnackbar((snackbar) => ({
        ...snackbar,
        open: true,
        text: "Your name please",
        severity: "error",
      }));

      return;
    }

    createUserWithEmailAndPassword(auth, user.email, user.password)
      .then(async (response) => {
        let date = moment();

        setSnackbar((snackbar) => ({
          ...snackbar,
          open: true,
          text: "See you!",
          severity: "success",
        }));

        await FirebaseService.insert("users", {
          full_name: user.fullName,
          email_address: user.email,
          confirmed_date: date.format("YYYY-MM-DD"),
          confirmed_date_unix: date.unix(),
        });
      })
      .catch((error) => {
        console.error(error);

        if (error.code.includes("email-already-in-use")) {
          setSnackbar((snackbar) => ({
            ...snackbar,
            open: true,
            text: "We've booked you already!",
            severity: "error",
          }));
        } else if (
          error.code.includes("missing-email") ||
          error.code.includes("invalid-email")
        ) {
          setSnackbar((snackbar) => ({
            ...snackbar,
            open: true,
            text: "Correct email address please",
            severity: "error",
          }));
        } else {
          setSnackbar((snackbar) => ({
            ...snackbar,
            open: true,
            text: "Oops! Something went wrong",
            severity: "error",
          }));
        }
      });
  };

  const handleEnterKey = (key) => {
    if (key === "Enter") {
      handleReservation();
    }
  };

  return (
    <React.Fragment>
      <Box component={"section"} id="home-banner">
        <Box className="home-banner-holder">
          <Box className="image-panel">
            <Box component={"img"} src={HomeBannerBg} alt="Home Banner" />
          </Box>
          <Box className="jumbotron-panel">
            <Box className="jumbotron">
              <Typography className="lead">Kim &amp; Neil</Typography>
              <Typography className="sub">Are getting married</Typography>
              <Box
                component={"img"}
                src={Heart}
                alt="Heart"
                className="heart-image"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box component={"section"} id="we-celebrate">
        <Box className="we-celebrate-holder">
          <Box className="jumbotron-panel">
            <Box className="jumbotron">
              <Typography className="lead">
                Join Us As We Celebrate our Union
              </Typography>
              <Typography className="sub">
                There is no fear in love. But perfect love drives out fear,
                because fear has to do with punishment. The one who fears is not
                made perfect in love. We love because he first loved us.
              </Typography>
              <Typography className="text">1 John 4: 18-19</Typography>
            </Box>
          </Box>
          <Box className="image-panel">
            <Box component={"img"} src={WeCelebrate} alt="Home Banner" />
          </Box>
        </Box>
      </Box>
      <Box component={"section"} id="details">
        <Container>
          <Typography className="section-title">
            Bear Witness to Our Special Day
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box className="card">
                <Box
                  component={"img"}
                  src={When}
                  alt="when"
                  className="image"
                />
                <Typography className="title">WHEN</Typography>
                <Typography className="date">June 15, 2024</Typography>
                <Typography className="time">3:00 pm</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="card">
                <Box
                  component={"img"}
                  src={Where}
                  alt="when"
                  className="image"
                />
                <Typography className="title">WHERE</Typography>
                <Typography className="date">Tishabet Fields</Typography>
                <Typography className="time">
                  Toclong II St. Imus, Cavite
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="card">
                <Box
                  component={"img"}
                  src={What}
                  alt="when"
                  className="image"
                />
                <Typography className="title">WHAT TO WEAR</Typography>
                <Typography className="date">Strictly ALL- BLACK</Typography>
                <Typography className="time">Semi-formal</Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box component={"section"} id="presence">
        <Container>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box className="jumbotron">
                <Typography className="lead">
                  Your presence at our wedding is the only present we require
                </Typography>
                <Typography className="sub">
                  However, if you desire to give, a monetary gift is one we
                  suggest.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component={"img"}
                src={PresenceImage}
                alt="hands"
                className="presence-image"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box id="rsvp">
        <Box className="rsvp-hero">
          <Typography>Kim and Neil</Typography>
          <Typography>
            <FavoriteBorderIcon />
            June 15, 2024
          </Typography>
        </Box>
        <Box className="rsvp-form">
          <Typography className="form-title">
            We hope to see you from the altar
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Full name"
                variant="outlined"
                autoComplete="off"
                name="fullName"
                value={user.fullName}
                onChange={(event) => handleOnChange(event)}
                onKeyUp={(event) => handleEnterKey(event.code)}
                color="white"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email address"
                variant="outlined"
                autoComplete="off"
                autocapitalize="none"
                name="email"
                value={user.email}
                onChange={(event) => handleOnChange(event)}
                onKeyUp={(event) => handleEnterKey(event.code)}
                color="white"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                className="submit-button"
                color="black"
                onClick={() => handleReservation()}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: snackbar.y, horizontal: snackbar.x }}
        autoHideDuration={snackbar.duration}
        open={snackbar.open}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.text}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
